import { AnyAction, Dispatch } from 'redux'

import { setJif } from 'state/jif'
import api from 'api'
import { auth } from 'components/GetGoAuthProvider'
import fetchWithToken from 'utils/fetchWithToken'
import handleStatusCodes from 'utils/handleStatusCodes'
import getConfig from 'config'

/**
 * Retrieves the connected user details based on the email in the token
 */
const fetchJif = (email: string): any => async (dispatch: Dispatch<AnyAction>) => {
	const route = `v4/user/jiveId/${email}`

	await fetchWithToken({
		method: 'GET',
		resource: api.jif,
		path: route,
		useV5: false,
		useVersion: false,
	})
		.then(async (response: Response) => {
			// Successful fetch
			if (response.status) {
				if (response.status === 401) {
					// re-login on 401
					localStorage.removeItem(getConfig().AUTH_DATA_STORAGE_KEY)
					auth.login()
				}

				if (!handleStatusCodes(response.status, route, dispatch, undefined, true)) return false

				const connectedUserDetails: { data: any } = await response.json()

				if (connectedUserDetails.data) dispatch(setJif(connectedUserDetails.data))
			}
		})
		.catch((err: Error) => {
			console.error(err)
			// Something wrong happened during the fetch
			// But we do nothing because this might be normal for some users // TODO: verify if true
		})
}

export default fetchJif
