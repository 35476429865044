import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import chameleon from 'theme/chameleon'

// Our Material-UI palette
// https://material-ui.com/customization/palette/#palette
const lightPalette: PaletteOptions = {
	type: 'light',
	text: {
		primary: chameleon.neutrals.n6,
		secondary: chameleon.neutrals.n4,
	},
	background: {
		default: '#f9fafc',
	},
	primary: {
		main: chameleon.blue.b5,
		dark: chameleon.blue.b6,
		contrastText: '#fff',
	},
	secondary: {
		main: chameleon.blue.b5,
		dark: chameleon.blue.b6,
		contrastText: '#fff',
	},
	error: {
		main: chameleon.red.r5,
		contrastText: '#fff',
	},
}

const darkPalette: PaletteOptions = {
	type: 'dark',
	text: {
		primary: '#fff',
		secondary: 'rgba(255, 255, 255, 0.7)',
	},
	background: {
		default: chameleon.neutrals.n6,
	},
	primary: {
		main: chameleon.blue.b3,
		dark: chameleon.blue.b4,
		contrastText: '#25282D',
	},
	secondary: {
		main: chameleon.blue.b3,
		dark: chameleon.blue.b4,
		contrastText: '#25282D',
	},
	error: {
		main: chameleon.red.r4,
		contrastText: '#fff',
	},
}

export { lightPalette, darkPalette }
