import { createMuiTheme } from '@material-ui/core/styles'
import { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

import { darkPalette } from 'theme/palette'
import { lightPalette } from 'theme/palette'
import chameleon, { Chameleon } from 'theme/chameleon'
import overrides from 'theme/overrides'
import typography from 'theme/typography'

// The ExtendedTheme type allows you to use chameleon colors in our components
export interface ExtendedTheme extends Theme {
	chameleon: Chameleon
}

interface ExtendedThemeOptions extends ThemeOptions {
	chameleon: Chameleon
}

const lightTheme: ExtendedThemeOptions = {
	...createMuiTheme({
		palette: lightPalette,
		typography,
		overrides,
	}),
	chameleon,
}

const darkTheme: ExtendedThemeOptions = {
	...createMuiTheme({
		palette: darkPalette,
		typography,
		overrides,
	}),
	chameleon,
}

export { darkTheme, lightTheme }
