import ReactGA from 'react-ga'
import getConfig from 'config'

const initGoogleAnalytics = () => {
	// We only want to inititalize Google Analytics when not in local
	if (!getConfig().IS_LOCAL_ENV) {
		const trackingId = getConfig().GOOGLE_ANALYTICS_TRACKING_ID
		ReactGA.initialize(trackingId)
	}
}

export default initGoogleAnalytics
