import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslate } from 'react-polyglot'

interface TitleProps {
	title?: string
}

// The AppTitle component takes care of setting the <title> of the page
const Title: FC<TitleProps> = ({ title }) => {
	const t = useTranslate()

	return (
		<Helmet>
			{title ? <title>{`${title} - ${t('End User Portal')}`}</title> : <title>{t('End User Portal')}</title>}
		</Helmet>
	)
}

export default Title
