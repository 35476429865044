import React, { FC, ReactNode } from 'react'
import { Slide, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ExtendedTheme } from 'theme'

const useStyles = makeStyles((theme: ExtendedTheme) => ({
	sidebar: {
		minHeight: '100vh',
		backgroundColor: theme.chameleon.neutrals.n1,
		borderRight: `1px solid ${theme.chameleon.neutrals.n2}`,
		width: 66,
		position: 'fixed',
		left: 0,
		top: 0,
		zIndex: 1100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'column',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}))

interface SidebarProps {
	children: ReactNode
}

const Sidebar: FC<SidebarProps> = ({ children }) => {
	const classes = useStyles()

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit>
			<Box className={classes.sidebar}>{children}</Box>
		</Slide>
	)
}

export default Sidebar
