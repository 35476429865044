import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface SelectedLine {
	id?: string
	name?: string
	number?: string
	mailbox?: {
		id?: string
		self?: string
	}
	devices?: any[]
}

const SelectedLine = createSlice({
	initialState: null as unknown,
	name: 'selectedLine',
	reducers: {
		setSelectedLine: (state, action: PayloadAction<SelectedLine>) => (state = action.payload),
	},
})

export const { setSelectedLine } = SelectedLine.actions

export default SelectedLine.reducer
