import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface ServiceSettings {
	jrn?: string
	name?: string
	extensionNumber?: string
	dialPlan?: {
		ringSteps?: any
		finalStep?: {
			action?: string
			extension?: string
			externalNumber?: string
		}
		unavailableStep?: {
			destinationType?: string
			extension?: string
			externalNumber?: string
		}
	}
	voicemailBox?: {
		jrn?: string
		timeZone?: string
		password?: string
		allowAccessWithoutPassword?: boolean
		grantAccessToAssociatedUsers?: boolean
		goToNextMessageAfterCommand?: boolean
		playMessageEnvelope?: boolean
		sayMessageCallerId?: boolean
		sayMessageDuration?: boolean
		sayMessageDurationThresholdMinutes?: number
		allowMessageReview?: boolean
		allowEscapeToOperator?: boolean
		operatorExtension?: string
		operatorExtensionName?: string
		sendEmailNotification?: boolean
		emailNotificationEmailAddress?: string
		useUsersEmailAddressForEmailNotification?: boolean
		transcriptionEnabled?: boolean
		attachMessageToEmailNotification?: boolean
		deleteMessageAfterEmailingNotification?: boolean
		attachedMessageFormat?: string
		sendPagerNotification?: boolean
		pagerNotificationEmailAddress?: string
	}
	voicemailBoxOverridden?: boolean
	interceptIncomingFaxes?: boolean
	useUsersEmailForIncomingFax?: boolean
	incomingFaxDestinationEmailAddress?: string
	forwardIncomingFaxes?: boolean
	forwardOutgoingFaxes?: boolean
	forwardFaxesNotificationEmail?: string
}

const ServiceSettings = createSlice({
	initialState: null as unknown,
	name: 'serviceSettings',
	reducers: {
		setServiceSettings: (state, action: PayloadAction<ServiceSettings>) => (state = action.payload),
	},
})

export const { setServiceSettings } = ServiceSettings.actions

export default ServiceSettings.reducer
