import { createSlice, PayloadAction } from 'redux-starter-kit'

const SelectedPbxId = createSlice({
	initialState: null as unknown,
	name: 'selectedPbxId',
	reducers: {
		setSelectedPbxId: (state, action: PayloadAction<string>) => (state = action.payload),
	},
})

export const { setSelectedPbxId } = SelectedPbxId.actions

export default SelectedPbxId.reducer
