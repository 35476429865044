import React, { FC, ReactNode } from 'react'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'

interface GoogleAnalyticsTrackerProps {
	children?: ReactNode
}

// This component tracks every page change and send them to Google Analytics
const GoogleAnalyticsTracker: FC<GoogleAnalyticsTrackerProps> = ({ children }) => {
	const history = useHistory()

	// Initialize Google Analytics page view tracking
	history.listen(location => {
		ReactGA.set({ page: location.pathname }) // Update the user's current page
		ReactGA.pageview(location.pathname) // Record a pageview for the given page
	})

	return <>{children}</>
}

export default GoogleAnalyticsTracker
