import { AnyAction, Dispatch } from 'redux'

import { setConnectedUserIdentity } from 'state/connectedUserIdentity'
import api from 'api'
import { auth } from 'components/GetGoAuthProvider'
import fetchWithToken from 'utils/fetchWithToken'
import handleStatusCodes from 'utils/handleStatusCodes'
import getConfig from 'config'

/**
 * Retrieves the connected user identity based on the externalUserKey in the token
 */
const fetchConnectedUserIdentity = (externalUserKey: string): any => async (dispatch: Dispatch<AnyAction>) => {
	const route = `principals/${externalUserKey}`

	await fetchWithToken({
		method: 'GET',
		resource: api.identity,
		path: route,
	})
		.then(async (response: Response) => {
			// Successful fetch
			if (response.status) {
				if (response.status === 401) {
					// re-login on 401
					localStorage.removeItem(getConfig().AUTH_DATA_STORAGE_KEY)
					auth.login()
				}

				if (!handleStatusCodes(response.status, route, dispatch, undefined, true)) return false

				const connectedUserIdentity = await response.json()

				if (connectedUserIdentity) dispatch(setConnectedUserIdentity(connectedUserIdentity))
			}
		})
		.catch((err: Error) => {
			console.error(err)
			// Something wrong happened during the fetch
			// But we do nothing because this might be normal for some users // TODO: verify if true
		})
}

export default fetchConnectedUserIdentity
