import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslate } from 'react-polyglot'

const Footer: FC = () => {
	const t = useTranslate()

	return (
		<Box component="footer" textAlign="center" py={2}>
			<Typography component="small" variant="caption" align="center" style={{ opacity: 0.65 }}>
				&copy; {new Date().getFullYear()}{' '}
				{`${window.location.hostname.includes('hvoice') ? 'hVoice' : 'LogMeIn, Inc.'}`} — {t('All rights reserved')}
			</Typography>
		</Box>
	)
}

export default Footer
