const baseApi = {
	applications: 'applications',
	cc: 'contact-center',
	cca: 'contact-center-agent',
	extension: 'extension',
	identity: 'identity',
	jif: 'jif',
	jiveweb: 'jiveweb',
	media: 'media',
	partner: 'partner',
	pbx: 'pbx',
	pstn: 'pstn',
	schedule: 'schedule',
	sdk: 'sdk',
	sip: 'sip',
	userAgent: 'user-agent',
	v5: 'v5',
	version: 'v1',
}

export default baseApi
