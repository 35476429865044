import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface Voicemail {
	messageNumber: number
	checksum: string
	callerIdName?: string
	callerIdNumber?: string
	date: string
	timestamp: number
	formattedDate?: string
	durationSeconds: number
	flagged?: boolean
	forwardedFromExtension?: any
	folder: string
	cboxIdBeta?: any
	cboxId?: {
		ulaw?: string
	}
}

export interface Voicemails {
	id: 'INBOX' | 'Old' | 'tmp'
	items: Voicemail[]
}

const Voicemails = createSlice({
	initialState: null as unknown,
	name: 'voicemails',
	reducers: {
		setVoicemails: (state, action: PayloadAction<Voicemails[]>) => (state = action.payload),
	},
})

export const { setVoicemails } = Voicemails.actions

export default Voicemails.reducer
