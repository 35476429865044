import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface Line {
	jrn?: string
	id?: string
	name?: string
	extensionNumber?: string
	omitFromAutomaticDirectories?: boolean
	sharedAppearance?: boolean
	type?: string
}

const Lines = createSlice({
	initialState: null as unknown,
	name: 'lines',
	reducers: {
		setLines: (state, action: PayloadAction<Line[]>) => (state = action.payload),
	},
})

export const { setLines } = Lines.actions

export default Lines.reducer
