import React, { FC, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

// Every page should be lazily loaded
export const MyAccount = lazy(() => import('pages/MyAccount'))
export const NotFound = lazy(() => import('pages/NotFound'))
export const ServiceSettings = lazy(() => import('pages/ServiceSettings'))

// All the routes go here
const Routes: FC = () => (
	<Switch>
		<Route exact path="/authenticate/callback" component={() => <Redirect to="/" />} />

		{/* My account routes */}
		<Route path="/:selectedPbxDomain/:tab" component={ServiceSettings} />

		<Redirect exact from="/:selectedPbxDomain" to="/:selectedPbxDomain/extensions-directory" />

		<Route path="/">
			<MyAccount />
		</Route>

		{/* Misc. */}
		<Route exact path="/(default)?" component={() => <Redirect to="/" />} />

		{/* Not Found */}
		<Route path="*">
			<NotFound />
		</Route>
	</Switch>
)

export default Routes
