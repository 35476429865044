import { createSlice, PayloadAction } from 'redux-starter-kit'

const ViewAs = createSlice({
	initialState: null as unknown,
	name: 'viewAs',
	reducers: {
		setViewAs: (state, action: PayloadAction<any>) => (state = action.payload),
	},
})

export const { setViewAs } = ViewAs.actions

export default ViewAs.reducer
