import { getEnvironment } from 'config'
import developmentApi from 'api/development.api'
import localApi from 'api/local.api'
import productionApi from 'api/production.api'
import stagingApi from 'api/staging.api'

let usedApi: any = {}

switch (getEnvironment()) {
	case 'production': {
		usedApi = productionApi
		break
	}
	case 'staging':
	case 'stageMyHvoice': {
		usedApi = stagingApi
		break
	}
	case 'development':
	case 'devMyHvoice': {
		usedApi = developmentApi
		break
	}
	case 'local': {
		usedApi = localApi
		break
	}
	default: {
		usedApi = productionApi
		break
	}
}

export default usedApi
