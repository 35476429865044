import { AnyAction, Dispatch } from 'redux'
import { setErrorMessage } from 'state/errorMessage'

interface ErrorMessages {
	[key: string]: string
}

export const ERROR_MESSAGES: ErrorMessages = {
	error401: `Unauthorized: you don't have access to this resource`,
	error403: `Forbidden: you don't have access to this resource`,
	error500: `Internal server error: the server was unable to complete your request`,
	generic: `Error: something bad happened`,
}

const handleStatusCodes = (
	status: number,
	route: string,
	dispatch: Dispatch<AnyAction>,
	message?: string,
	noDispatch = false,
) => {
	if (status >= 200 && status < 300) {
		return true
	} else {
		console.error(message)
		if (!noDispatch) {
			switch (status) {
				case 401:
					dispatch(setErrorMessage(message || ERROR_MESSAGES.error401))
					return false
				case 403:
					dispatch(setErrorMessage(message || ERROR_MESSAGES.error403))
					return false
				case 500:
					dispatch(setErrorMessage(message || ERROR_MESSAGES.error500))
					return false
				default:
					// Do nothing for now
					//dispatch(setErrorMessage(message || ERROR_MESSAGES.generic))
					return false
			}
		}
	}
}

export default handleStatusCodes
