import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface SelectedPbx {
	domain?: string
	extensions?: any[]
	id: string
	name?: string
	permissions?: {
		reporting?: boolean
	}
	phoneNumbers?: string[]
	v5?: boolean
	userInfo?: {
		userId?: string
		first?: string
		last?: string
		fullName?: string
	}
	type?: string
}

const SelectedPbx = createSlice({
	initialState: null as unknown,
	name: 'selectedPbx',
	reducers: {
		setSelectedPbx: (state, action: PayloadAction<string>) => (state = action.payload),
	},
})

export const { setSelectedPbx } = SelectedPbx.actions

export default SelectedPbx.reducer
