import { createSlice, PayloadAction } from 'redux-starter-kit'

const initialState = ''

const ErrorMessage = createSlice({
	initialState,
	name: 'errorMessage',
	reducers: {
		setErrorMessage: (state, action: PayloadAction<string>) => (state = action.payload),
	},
})

export const { setErrorMessage } = ErrorMessage.actions

export default ErrorMessage.reducer
