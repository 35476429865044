import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface Extension {
	jrn?: string
	name?: string
	extensionNumber?: string
	omitFromAutomaticDirectories?: boolean
	voicemailBoxId?: string
	type?: string
}

const Extensions = createSlice({
	initialState: null as unknown,
	name: 'extensions',
	reducers: {
		setExtensions: (state, action: PayloadAction<Extension[]>) => (state = action.payload),
	},
})

export const { setExtensions } = Extensions.actions

export default Extensions.reducer
