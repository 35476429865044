import { createSlice, PayloadAction } from 'redux-starter-kit'

const Jif = createSlice({
	initialState: null as unknown,
	name: 'jif',
	reducers: {
		setJif: (state, action: PayloadAction<any>) => (state = action.payload),
	},
})

export const { setJif } = Jif.actions

export default Jif.reducer
