import { SnackbarProvider as Notistack } from 'notistack'
import React, { FC, ReactNode } from 'react'
import { SvgIcon, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import WarningIcon from 'assets/icons/WarningIcon'
import CheckIcon from 'assets/icons/CheckIcon'
import chameleon from 'theme/chameleon'

const useStyles = makeStyles(() => ({
	info: {
		backgroundColor: chameleon.blue.b5,
	},
}))

interface SnackbarProviderProps {
	children: ReactNode
}

// Provides the snackbars throughout the application
const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
	const classes = useStyles()

	return (
		<Notistack
			classes={{
				variantInfo: classes.info,
			}}
			iconVariant={{
				success: (
					<Box position="relative" top={3} mr={1}>
						<SvgIcon fontSize="small">
							<CheckIcon />
						</SvgIcon>
					</Box>
				),
				error: (
					<Box position="relative" top={3} mr={1}>
						<SvgIcon fontSize="small">
							<WarningIcon />
						</SvgIcon>
					</Box>
				),
			}}
		>
			{children}
		</Notistack>
	)
}

export default SnackbarProvider
