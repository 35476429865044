import {
	Box,
	Menu,
	ListItemIcon,
	ListItemText,
	SvgIcon,
	ListItem,
	Typography,
	Tooltip,
	IconButton,
} from '@material-ui/core'
import React, { MouseEvent, useState, FC } from 'react'
import { useTranslate } from 'react-polyglot'

import CheckIcon from 'assets/icons/CheckIcon'
import { I18nContext } from 'components/App'
import GlobeIcon from 'assets/icons/GlobeIcon'
import languages, { Language } from 'utils/languages'

// Simple language switcher
const LanguageSwitcher: FC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const t = useTranslate()

	const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	/*const renderLanguageLabel = (locale: string) => {
		const selectedLanguage = Object.values(languages).filter(language => language.key === locale)[0]
		if (selectedLanguage) {
			return `${selectedLanguage.lang} (${selectedLanguage.country})`
		}
	}*/

	return (
		<I18nContext.Consumer>
			{i18n => (
				<>
					{i18n && (
						<>
							<Tooltip title={t('Change Language')} placement="right">
								<IconButton
									aria-controls="language-menu"
									aria-haspopup="true"
									onClick={openMenu}
									color="inherit"
									aria-label={t('Change Language')}
								>
									<SvgIcon fontSize="small">
										<GlobeIcon />
									</SvgIcon>
								</IconButton>
							</Tooltip>
							<Menu id="language-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
								{Object.values(languages).map((language: Language) => (
									<ListItem
										component={'li'}
										button
										onClick={() => {
											i18n.setLocale(language.key)
											handleClose()
										}}
										key={language.key}
									>
										{language.key === i18n.locale && (
											<ListItemIcon>
												<SvgIcon>
													<CheckIcon />
												</SvgIcon>
											</ListItemIcon>
										)}
										<ListItemText
											inset={language.key === i18n.locale ? false : true}
											lang={language.key.substring(0, 2)} // For screen readers, they know how to pronounce the language (https://twitter.com/romano_cla/status/1130832868039581696)
											primary={
												<Box pr={2}>
													{language && <Typography>{`${language.lang} (${language.country})`}</Typography>}
												</Box>
											}
										/>
									</ListItem>
								))}
							</Menu>
						</>
					)}
				</>
			)}
		</I18nContext.Consumer>
	)
}

export default LanguageSwitcher
