import AppConfig from 'config/AppConfig'
import AuthConfig from 'config/AuthConfig'

export const getEnvironment = (): string | undefined =>
	AppConfig && Object.keys(AppConfig).find(env => new URL(AppConfig[env].APP_URL).hostname === window.location.hostname)

export const getConfig = () => ({
	...AuthConfig.base,
	...AuthConfig[getEnvironment() || 'local'],
	...AppConfig[getEnvironment() || 'local'],
	ENV: getEnvironment(),
	IS_TEST_ENV: navigator.userAgent === 'chromium-e2e',
})

export default getConfig
