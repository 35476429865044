import { createSlice, PayloadAction } from 'redux-starter-kit'

const initialState = ''

const SuccessMessage = createSlice({
	initialState,
	name: 'successMessage',
	reducers: {
		setSuccessMessage: (state, action: PayloadAction<string>) => (state = action.payload),
	},
})

export const { setSuccessMessage } = SuccessMessage.actions

export default SuccessMessage.reducer
