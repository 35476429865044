import { createSlice, PayloadAction } from 'redux-starter-kit'

const IAm = createSlice({
	initialState: null as unknown,
	name: 'iAm',
	reducers: {
		setIAm: (state, action: PayloadAction<any>) => (state = action.payload),
	},
})

export const { setIAm } = IAm.actions

export default IAm.reducer
