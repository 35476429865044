import { AnyAction, Dispatch } from 'redux'

import { setIAm } from 'state/iAm'
import fetchWithToken from 'utils/fetchWithToken'
import handleStatusCodes from 'utils/handleStatusCodes'
import getConfig from 'config'

/**
 * Retrieves the user's logmein infos
 */
const fetchIAm = (): any => async (dispatch: Dispatch<AnyAction>) => {
	const iAmUrl = getConfig().I_AM_URL
	const route = `${iAmUrl}/identity/v1/Users/me`

	await fetchWithToken({
		method: 'GET',
		path: route,
		useBasePath: false,
		useV5: false,
		useVersion: false,
	})
		.then(async (response: Response) => {
			// Successful fetch
			if (response.status) {
				if (!handleStatusCodes(response.status, route, dispatch)) return false

				const iAm: any = await response.json()

				if (iAm) {
					dispatch(setIAm(iAm))
				}
			}
		})
		.catch((err: Error) => {
			// Something wrong happened during the fetch
			// dispatch(setErrorMessage(err.toString()))
		})
}

export default fetchIAm
