import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import React, { FC, ReactNode, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Helmet from 'react-helmet'
import { useTranslate } from 'react-polyglot'

import Sidebar from 'components/Sidebar'
import Footer from 'components/Footer'
import Title from 'components/Title'
import UserMenu from 'components/UserMenu'
import AppState from 'state/appState'
import { ExtendedTheme } from 'theme'

const useStyles = makeStyles((theme: ExtendedTheme) => ({
	main: {
		paddingLeft: theme.spacing(14),
		marginBottom: theme.spacing(4),
		paddingTop: theme.spacing(4),
		minHeight: 'calc(100vh - 85px)',
	},
	header: {
		position: 'fixed',
		display: 'flex',
		justifyContent: 'flex-end',
		left: 0,
		right: 0,
		backgroundColor: theme.palette.background.paper,
		zIndex: 1100,
		top: 0,
		height: 72,
		borderBottom: `1px solid ${
			theme.palette.type === 'dark' ? theme.chameleon.neutrals.n5 : theme.chameleon.neutrals.n2
		}`,
	},
}))

interface LayoutProps extends WithSnackbarProps {
	children?: ReactNode
	connectedUser: AppState['connectedUser']
	errorMessage: AppState['errorMessage']
	successMessage: AppState['successMessage']
	viewAs: AppState['viewAs']
}

// The layout component puts together the Navbar and the content
const Layout: FC<LayoutProps> = ({
	connectedUser,
	children,
	enqueueSnackbar,
	errorMessage,
	successMessage,
	viewAs,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const t = useTranslate()

	useEffect(() => {
		if (errorMessage) {
			enqueueSnackbar(errorMessage, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
				// Reset the error message in case we want to display the same message a second time
				onExited: () => dispatch({ type: 'errorMessage/setErrorMessage', payload: '' }),
			})
		}
	}, [dispatch, enqueueSnackbar, errorMessage])

	useEffect(() => {
		if (successMessage) {
			enqueueSnackbar(successMessage, {
				variant: 'success',
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
				// Reset the success message in case we want to display the same message a second time
				onExited: () => dispatch({ type: 'successMessage/setSuccessMessage', payload: '' }),
			})
		}
	}, [dispatch, enqueueSnackbar, successMessage])

	// Show a notice if we're viewing the application as someone else
	useEffect(() => {
		if (viewAs && connectedUser) {
			enqueueSnackbar(
				`${t('Viewing the End User Portal as')} ${connectedUser?.users?.[0]?.firstName || ''} ${connectedUser
					?.users?.[0]?.lastName || ''} `,
				{
					variant: 'info',
					persist: true,
					preventDuplicate: true,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					//action: dismissAction,
				},
			)
		}
	}, [connectedUser, enqueueSnackbar, t, viewAs])

	return (
		<>
			<Title />
			<Helmet>
				{window.location.hostname.includes('hvoice') && (
					<link rel="icon" type="image/x-icon" sizes="32x32" href="/me/hvoice.ico" />
				)}
			</Helmet>

			{/*<Box display="flex" alignItems="center" px={4} py={2} component="header" className={classes.header}>
				<UserMenu />
				</Box>*/}

			<Sidebar>
				<UserMenu />
			</Sidebar>

			<Container component="main" className={classes.main} maxWidth="xl">
				{children}
			</Container>

			<Footer />
		</>
	)
}

export default withSnackbar(
	connect(({ connectedUser, errorMessage, successMessage, viewAs }: AppState) => ({
		connectedUser,
		errorMessage,
		successMessage,
		viewAs,
	}))(Layout),
)
