import { createSlice, PayloadAction } from 'redux-starter-kit'

const UnreadVoicemails = createSlice({
	initialState: null as unknown,
	name: 'unreadVoicemails',
	reducers: {
		setUnreadVoicemails: (state, action: PayloadAction<number>) => (state = action.payload),
	},
})

export const { setUnreadVoicemails } = UnreadVoicemails.actions

export default UnreadVoicemails.reducer
