import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface ConnectedUserIdentity {}

const ConnectedUserIdentity = createSlice({
	initialState: null as unknown,
	name: 'connectedUserIdentity',
	reducers: {
		setConnectedUserIdentity: (state, action: PayloadAction<ConnectedUserIdentity>) => (state = action.payload),
	},
})

export const { setConnectedUserIdentity } = ConnectedUserIdentity.actions

export default ConnectedUserIdentity.reducer
