import { configureStore, getDefaultMiddleware } from 'redux-starter-kit'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import React, { createContext, FC, Suspense } from 'react'
import { I18n } from 'react-polyglot'
import createPersistedState from 'use-persisted-state'

import SnackbarProvider from 'components/SnackbarProvider'
import GoogleAnalyticsTracker from 'components/GoogleAnalyticsTracker'
// import CacheBuster from 'components/CacheBuster'
import ErrorBoundary from 'components/ErrorBoundary'
import Layout from 'components/Layout'
import ThemeSwitcher from 'components/ThemeSwitcher'
import Title from 'components/Title'
import Routes from 'components/Routes'
import ConnectedUserProvider from 'components/ConnectedUserProvider'
import GetGoAuthProvider from 'components/GetGoAuthProvider'
import getConfig from 'config'

// All the reducers
import buttonConfiguration from 'state/buttonConfiguration'
import connectedUser from 'state/connectedUser'
import connectedUserIdentity from 'state/connectedUserIdentity'
import errorMessage from 'state/errorMessage'
import extensions from 'state/extensions'
import iAm from 'state/iAm'
import jif from 'state/jif'
import lines from 'state/lines'
import serviceSettings from 'state/serviceSettings'
import selectedLine from 'state/selectedLine'
import selectedPbxId from 'state/selectedPbxId'
import selectedPbx from 'state/selectedPbx'
import successMessage from 'state/successMessage'
import unreadVoicemails from 'state/unreadVoicemails'
import viewAs from 'state/viewAs'
import voicemails from 'state/voicemails'

// Translation files
import deDE from 'translations/de-DE.json'
import enGB from 'translations/en-GB.json'
import enUS from 'translations/en-US.json'
import esES from 'translations/es-ES.json'
import esMX from 'translations/es-MX.json'
import frCA from 'translations/fr-CA.json'
import frFR from 'translations/fr-FR.json'
import ptBR from 'translations/pt-BR.json'

// We create the history variable that will get passed around our react-router instance
const history = createBrowserHistory({ basename: '/me' })

// We declare our middleware
const middleware = [...getDefaultMiddleware()]

// We declare our store
export const store = configureStore({
	reducer: {
		buttonConfiguration,
		connectedUser,
		connectedUserIdentity,
		errorMessage,
		extensions,
		iAm,
		jif,
		lines,
		selectedLine,
		selectedPbx,
		selectedPbxId,
		serviceSettings,
		successMessage,
		unreadVoicemails,
		viewAs,
		voicemails,
	},
	middleware,
})

// We declare our messages
export const messages = {
	'en-US': enUS,
	'fr-CA': frCA,
	'de-DE': deDE,
	'en-GB': enGB,
	'es-ES': esES,
	'es-MX': esMX,
	'fr-FR': frFR,
	'pt-BR': ptBR,
}

// The i18n context to pass the locale around
export const I18nContext = createContext<any>(null)

// Creating the language locale in localeStoage
const useLocalState = createPersistedState(getConfig().LOCALE_STORAGE_KEY)

const App: FC = () => {
	const [locale, setLocale] = useLocalState('en-US') // fallback is en-US

	return (
		<>
			{/*<CacheBuster>
				{({ loading, isLatestVersion, refreshCacheAndReload }) => {
					if (loading) return null
					if (!loading && !isLatestVersion) {
						// Only force refresh in production
						refreshCacheAndReload()
					}

				return (*/}
			<Provider store={store}>
				<I18n locale={locale} messages={messages[locale]}>
					<ConnectedUserProvider>
						<ThemeSwitcher>
							<CssBaseline />
							<SnackbarProvider>
								<Router history={history}>
									<GetGoAuthProvider>
										<GoogleAnalyticsTracker>
											<I18nContext.Provider value={{ locale, setLocale }}>
												<Layout>
													<ErrorBoundary>
														<Suspense fallback={<Title title={'Loading...'} />}>
															<Routes />
														</Suspense>
													</ErrorBoundary>
												</Layout>
											</I18nContext.Provider>
										</GoogleAnalyticsTracker>
									</GetGoAuthProvider>
								</Router>
							</SnackbarProvider>
						</ThemeSwitcher>
					</ConnectedUserProvider>
				</I18n>
			</Provider>
			{/*	)
				}}
			</CacheBuster>*/}
		</>
	)
}

export default App
