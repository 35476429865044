export interface Chameleon {
	blue: {
		b1: string
		b2: string
		b3: string
		b4: string
		b5: string
		b6: string
	}
	green: {
		g1: string
		g2: string
		g3: string
		g4: string
		g5: string
		g6: string
	}
	purple: {
		p1: string
		p2: string
		p3: string
		p4: string
		p5: string
		p6: string
	}
	yellow: {
		y1: string
		y2: string
		y3: string
		y4: string
		y5: string
		y6: string
	}
	orange: {
		o1: string
		o2: string
		o3: string
		o4: string
		o5: string
		o6: string
	}
	red: {
		r1: string
		r2: string
		r3: string
		r4: string
		r5: string
		r6: string
	}
	neutrals: {
		n1: string
		n2: string
		n3: string
		n4: string
		n5: string
		n6: string
	}
}

// Our chameleon colors
// https://www.chameleondesignsystem.com/foundation/color
const chameleon: Chameleon = {
	blue: {
		b1: '#D9E9FF',
		b2: '#BFDAFF',
		b3: '#80B5FF',
		b4: '#4090FF',
		b5: '#006AFF',
		b6: '#004FBD',
	},
	green: {
		g1: '#D9FFF2',
		g2: '#80FFD2',
		g3: '#00CC85',
		g4: '#00AA6E',
		g5: '#008858',
		g6: '#166549',
	},
	purple: {
		p1: '#E6D9FF',
		p2: '#D5BFFF',
		p3: '#AC80FF',
		p4: '#9B66FF',
		p5: '#7B44E4',
		p6: '#6739BF',
	},
	yellow: {
		y1: '#FFF7D9',
		y2: '#FFE88C',
		y3: '#FFCB00',
		y4: '#D9AD00',
		y5: '#B38F00',
		y6: '#997A00',
	},
	orange: {
		o1: '#FEEDDE',
		o2: '#FFC48C',
		o3: '#F68D2E',
		o4: '#DB781C',
		o5: '#B35F11',
		o6: '#8C4400',
	},
	red: {
		r1: '#FEE2E8',
		r2: '#FABCC8',
		r3: '#FD94A9',
		r4: '#FD4C6F',
		r5: '#E02D52',
		r6: '#A12E45',
	},
	neutrals: {
		n1: '#F0F2F5',
		n2: '#E0E4EA',
		n3: '#9AA8BB',
		n4: '#6B7582',
		n5: '#4C535D',
		n6: '#25282D',
	},
}

export default chameleon
