import { ThemeProvider } from '@material-ui/core/styles'
import React, { FC, ReactNode } from 'react'

import { lightTheme /*, darkTheme*/ } from 'theme'
//import { useDarkMode } from 'hooks/useDarkMode'

interface ThemeSwitcherProps {
	children: ReactNode
}

// The ThemeSwitcher component provides the MaterialUI (light/dark)
const ThemeSwitcher: FC<ThemeSwitcherProps> = ({ children }) => {
	//const [darkModeEnabled] = useDarkMode()

	return <ThemeProvider theme={/*darkModeEnabled ? darkTheme :*/ lightTheme}>{children}</ThemeProvider>
}

export default ThemeSwitcher
