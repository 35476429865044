import { TypographyOptions } from '@material-ui/core/styles/createTypography'

// Our Material-UI typography
// https://material-ui.com/customization/typography/#typography
const typography: TypographyOptions = {
	fontFamily: [
		'Lato',
		'San Francisco',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	fontWeightBold: 700,
	h2: {
		fontWeight: 700,
		fontSize: 24,
	},
	h6: {
		fontWeight: 700,
	},
}

export default typography
