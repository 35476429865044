export type AppConfig = {
	[key in 'local' | 'development' | 'staging' | 'production' | 'devMyHvoice' | 'stageMyHvoice' | 'myHvoice']: {
		APP_URL: string
		CLIENT_ID?: string
		AUTH_SERVICE_URL?: string
		GOOGLE_ANALYTICS_TRACKING_ID?: string
		I_AM_URL?: string
		REDIRECT_URL?: string
		IS_LOCAL_ENV: boolean
		GTC_URL?: string
	}
}

const AppConfig: AppConfig = {
	local: {
		APP_URL: 'http://localhost:3000/me',
		AUTH_SERVICE_URL: 'https://authenticationed1.lmiinc.test.expertcity.com',
		CLIENT_ID: 'a715f9c4-7dfa-4755-bef9-18a7b0fec0fa',
		REDIRECT_URL: 'http://localhost:3000/me/authenticate/callback',
		I_AM_URL: 'https://iamed1.serversdev.getgo.com',
		IS_LOCAL_ENV: true,
		GTC_URL: 'http://localhost:9000/',
	},
	development: {
		APP_URL: 'https://my.dev.jive.com/me',
		GOOGLE_ANALYTICS_TRACKING_ID: 'UA-126153964-16',
		AUTH_SERVICE_URL: 'https://authenticationed1.lmiinc.test.expertcity.com',
		CLIENT_ID: 'a715f9c4-7dfa-4755-bef9-18a7b0fec0fa',
		REDIRECT_URL: 'https://my.dev.jive.com/me/authenticate/callback',
		I_AM_URL: 'https://iamed1.serversdev.getgo.com',
		IS_LOCAL_ENV: false,
		GTC_URL: 'https://app.dev.gtc.goto.com/',
	},
	staging: {
		APP_URL: 'https://my.staging.jive.com/me',
		GOOGLE_ANALYTICS_TRACKING_ID: 'UA-126153964-17',
		AUTH_SERVICE_URL: 'https://authenticationstage.logmeininc.com',
		CLIENT_ID: 'cffbef45-91b5-4b9d-ba89-9a3f16509148',
		REDIRECT_URL: 'https://my.staging.jive.com/me/authenticate/callback',
		I_AM_URL: 'https://iamstage.servers.getgo.com',
		IS_LOCAL_ENV: false,
		GTC_URL: 'https://app.staging.gtc.goto.com/',
	},
	production: {
		APP_URL: 'https://my.jive.com/me/',
		GOOGLE_ANALYTICS_TRACKING_ID: 'UA-126153964-18',
		AUTH_SERVICE_URL: 'https://authentication.logmeininc.com',
		CLIENT_ID: 'a03d7152-b394-4e2c-8523-c3069be932da',
		REDIRECT_URL: 'https://my.jive.com/me/authenticate/callback',
		I_AM_URL: 'https://iam.servers.getgo.com',
		IS_LOCAL_ENV: false,
		GTC_URL: 'https://app.goto.com/',
	},
	devMyHvoice: {
		APP_URL: 'https://my.dev.hvoice.net/me/',
		GOOGLE_ANALYTICS_TRACKING_ID: 'UA-126153964-16',
		AUTH_SERVICE_URL: 'https://authenticationed1.lmiinc.test.expertcity.com',
		CLIENT_ID: 'a715f9c4-7dfa-4755-bef9-18a7b0fec0fa',
		REDIRECT_URL: 'https://my.dev.hvoice.net/me/authenticate/callback',
		I_AM_URL: 'https://iamed1.serversdev.getgo.com',
		IS_LOCAL_ENV: false,
	},
	stageMyHvoice: {
		APP_URL: 'https://my.staging.hvoice.net/me/',
		GOOGLE_ANALYTICS_TRACKING_ID: 'UA-126153964-17',
		AUTH_SERVICE_URL: 'https://authenticationstage.logmeininc.com',
		CLIENT_ID: 'cffbef45-91b5-4b9d-ba89-9a3f16509148',
		REDIRECT_URL: 'https://my.staging.hvoice.net/me/authenticate/callback',
		I_AM_URL: 'https://iamstage.servers.getgo.com',
		IS_LOCAL_ENV: false,
	},
	myHvoice: {
		APP_URL: 'https://my.hvoice.net/me/',
		GOOGLE_ANALYTICS_TRACKING_ID: 'UA-126153964-19',
		AUTH_SERVICE_URL: 'https://authentication.logmeininc.com',
		CLIENT_ID: 'cf927a7e-3cdc-495d-8a44-dce6391857ec',
		REDIRECT_URL: 'https://my.hvoice.net/me/authenticate/callback',
		I_AM_URL: 'https://iam.servers.getgo.com',
		IS_LOCAL_ENV: false,
	},
}

export default AppConfig
