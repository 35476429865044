import React, { FC, useState } from 'react'
import { auth } from 'components/GetGoAuthProvider'
import { SvgIcon, CircularProgress, Tooltip, IconButton } from '@material-ui/core'
import { useTranslate } from 'react-polyglot'

import LogoutIcon from 'assets/icons/LogoutIcon'
import getConfig from 'config'

const LogoutButton: FC = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const t = useTranslate()

	return (
		<Tooltip title={t('Logout')} placement="right">
			<IconButton
				onClick={() => {
					localStorage.removeItem(getConfig().AUTH_DATA_STORAGE_KEY)
					setLoading(true)
					auth.logout()
				}}
				color="inherit"
				aria-label={t('Logout')}
			>
				{loading ? (
					<CircularProgress disableShrink size={14} color="inherit" />
				) : (
					<SvgIcon fontSize="small" style={{ position: 'relative', left: -1 }}>
						<LogoutIcon />
					</SvgIcon>
				)}
			</IconButton>
		</Tooltip>
	)
}

export default LogoutButton
