import React, { FC } from 'react'

const CheckIcon: FC = () => (
	<g fill="none" fillRule="evenodd">
		<path d="M0 0h24v24H0z"></path>
		<path
			d="M20.879 4.837L8.547 17.17l-5.365-5.366a1 1 0 1 0-1.414 1.415L7.84 19.29a.999.999 0 0 0 1.414 0L22.293 6.251a.999.999 0 1 0-1.414-1.414"
			fill="currentColor"
		></path>
	</g>
)

export default CheckIcon
