import { Overrides } from '@material-ui/core/styles/overrides'

// Everything Material-UI related that we want to override goes here
// https://material-ui.com/customization/components/#global-theme-override
const overrides: Overrides = {
	MuiButton: {
		root: {
			textTransform: 'none',
			fontWeight: 'bold',
		},
		containedPrimary: {
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'none',
			},
			'&:active': {
				boxShadow: 'none',
			},
		},
	},
	MuiListItemIcon: {
		root: {
			minWidth: 38,
		},
	},
	MuiFormControl: {
		marginDense: {
			marginTop: 0,
			marginBottom: 0,
		},
	},
	MuiTypography: {
		caption: {
			position: 'relative',
			top: 2,
		},
	},
	MuiLink: {
		root: {
			cursor: 'pointer',
		},
	},
	MuiTab: {
		root: {
			fontWeight: 'bold',
		},
	},
	MuiListItemText: {
		inset: {
			paddingLeft: 38,
		},
	},
	MuiButtonBase: {
		root: {
			'&:disabled': {
				cursor: 'not-allowed',
				pointerEvents: 'auto',
			},
		},
	},
	MuiSnackbar: {
		anchorOriginBottomLeft: {
			marginLeft: 72,
		},
	},
}

export default overrides
