import React, { FC } from 'react'

const LogoutIcon: FC = () => (
	<g fill="none" fillRule="evenodd">
		<path d="M0 0h24v24H0z"></path>
		<path
			d="M19.5 2.531a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2v-2.654a1 1 0 0 1 2 0v2.654h7v-15h-7v2.346a1 1 0 0 1-2 0V4.531a2 2 0 0 1 2-2h7zm-2.5 8.5a1 1 0 0 1 0 2l-11.09-.005 2.54 2.541a1 1 0 0 1-1.414 1.414l-4.243-4.243a.999.999 0 0 1 0-1.414l4.243-4.243A1 1 0 0 1 8.45 8.495l-2.531 2.531L17 11.031z"
			fill="currentColor"
		></path>
	</g>
)

export default LogoutIcon
