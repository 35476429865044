import React, { FC } from 'react'

const WarningIcon: FC = () => (
	<g fill="none" fillRule="evenodd">
		<path d="M0 0h24v24H0z"></path>
		<path
			d="M12 2.482a.99.99 0 0 1 .866.5l9.836 17.036a1 1 0 0 1-.866 1.5H2.164a1 1 0 0 1-.866-1.5l9.836-17.036a.99.99 0 0 1 .866-.5zm0 3L3.896 19.518h16.208L12 5.482zM12 18.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1-4c0 .55-.45 1-1 1s-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4z"
			fill="currentColor"
		></path>
	</g>
)

export default WarningIcon
