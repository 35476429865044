import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface ConnectedUser {
	jrn?: string
	securedResourceIdentity?: string
	locked?: boolean
	enabled?: boolean
	memberOfUserGroups?: any[]
	emailAddress?: string
	firstName?: string
	lastName?: string
	dialableUsername?: string
	dialablePassword?: string
	externalUserId?: string
	externalUserKey?: string
	softphoneEnabled?: boolean
	mobilityEnabledOnPbx?: any
	designatedSoftphoneDeviceMasterConfiguration?: any
	isPbxAdmin?: boolean
	hasSupportPin?: boolean
	pbxPermissions?: any[]
	pbxDomain?: string
	pbxName?: string
}

const ConnectedUser = createSlice({
	initialState: null as unknown,
	name: 'connectedUser',
	reducers: {
		setConnectedUser: (state, action: PayloadAction<ConnectedUser>) => (state = action.payload),
	},
})

export const { setConnectedUser } = ConnectedUser.actions

export default ConnectedUser.reducer
