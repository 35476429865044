import React, { FC } from 'react'

const GlobeIcon: FC = () => (
	<g fill="none" fillRule="evenodd">
		<path d="M0 0h24v24H0z"></path>
		<path
			d="M11.042 20.95v-3.43c-.776.032-1.542.103-2.297.21a22.832 22.832 0 0 0 1.006 2.987c.419.108.85.186 1.291.233zm-3.82-1.321a24.841 24.841 0 0 1-.444-1.53c-.376.088-.748.185-1.116.291.472.468.995.884 1.56 1.239zm-2.897-2.926a24.834 24.834 0 0 1 2.035-.562A24.962 24.962 0 0 1 6.024 13h-2.97a8.944 8.944 0 0 0 1.271 3.703zM3.055 11h2.961a24.97 24.97 0 0 1 .313-3.148 24.84 24.84 0 0 1-2.004-.555A8.944 8.944 0 0 0 3.055 11zm2.607-5.39c.353.101.71.195 1.07.28.123-.496.261-.986.413-1.47a9.04 9.04 0 0 0-1.483 1.19zm4.002-2.304c-.386.956-.71 1.943-.966 2.957.77.111 1.552.184 2.344.217V3.05c-.471.05-.932.136-1.378.256zm3.378-.246v3.416a22.962 22.962 0 0 0 2.237-.21 22.834 22.834 0 0 0-.984-2.97 8.964 8.964 0 0 0-1.253-.236zm3.774 1.335c.158.494.301.994.429 1.5.368-.086.732-.182 1.093-.285a9.04 9.04 0 0 0-1.522-1.215zm2.859 2.902c-.662.213-1.335.4-2.02.558.173 1.029.283 2.078.325 3.145h2.965a8.944 8.944 0 0 0-1.27-3.703zM20.945 13H17.98a24.963 24.963 0 0 1-.324 3.144c.684.16 1.357.346 2.019.56A8.944 8.944 0 0 0 20.945 13zm-2.607 5.39c-.36-.104-.725-.199-1.093-.285-.128.506-.27 1.006-.429 1.5a9.04 9.04 0 0 0 1.522-1.215zm-4.044 2.315c.393-.96.723-1.952.985-2.97a22.97 22.97 0 0 0-2.237-.211v3.416c.427-.049.845-.128 1.252-.235zM12 .995c6.075 0 11 4.925 11 11 0 6.076-4.925 11-11 11s-11-4.924-11-11c0-6.075 4.925-11 11-11zM8.324 15.769a25.062 25.062 0 0 1 2.718-.25V13H8.026c.044.939.144 1.863.298 2.77zm4.718-.247c.896.036 1.78.12 2.65.25.15-.907.246-1.832.287-2.772h-2.937v2.522zM8.017 11h3.025V8.482a25.06 25.06 0 0 1-2.749-.256c-.146.908-.24 1.834-.276 2.774zm5.025-2.521V11h2.937a23.07 23.07 0 0 0-.287-2.772c-.87.13-1.754.214-2.65.25z"
			fill="currentColor"
		></path>
	</g>
)

export default GlobeIcon
