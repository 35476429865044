import React, { FC } from 'react'
import Auth from '@getgo/auth-client'
import getConfig from 'config'

const options = {
	client_id: getConfig().CLIENT_ID,
	url: getConfig().AUTH_SERVICE_URL,
	redirect_url: getConfig().REDIRECT_URL,
	enableTokenRefresh: true,
}

export const auth = new Auth(options)

const GetGoAuthProvider: FC = ({ children }) => {
	const tokenFromCb = auth.init()
	if (tokenFromCb) {
		window['lmi-end-user-portal-token'] = tokenFromCb
		localStorage.setItem(getConfig().AUTH_DATA_STORAGE_KEY, JSON.stringify(tokenFromCb))

		if(tokenFromCb.state) {
			window.location.assign(tokenFromCb.state.inflightRequest)
		} else {
			window.location.assign('/me')
		}
		return null
	} else {
		const storedToken = localStorage.getItem(getConfig().AUTH_DATA_STORAGE_KEY)
		if (storedToken) {
			window['lmi-end-user-portal-token'] = JSON.parse(storedToken)
		} else {
			auth.login({
				state: { inflightRequest: window.location.href },
			})
			return null
		}
	}
	return <>{window['lmi-end-user-portal-token'] && children}</>
}

export default GetGoAuthProvider
