import React from 'react'
import ReactDOM from 'react-dom'

import 'theme/global.scss'
import App from 'components/App'
import * as serviceWorker from './serviceWorker'
// @ts-ignore
import packageJson from '../package.json'
import initGoogleAnalytics from 'utils/initGoogleAnalytics'

// Init Google Analytics
initGoogleAnalytics()

// Setting the app version so we can check in the browser console in production
window['appVersion'] = packageJson.version

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
