import api from 'api'

import getAuthData from 'utils/getAuthData'

/**
 * This method is very powerful
 * It takes care of pretty much all API calls throughout the application
 * Its main pupose is to wrap every call with the auth token
 */
interface FetchWithTokenProps {
	method: string // get, post, put, delete
	resource?: string // see baseApi for all the resources
	path: string // the API endpoint we want to hit
	filters?: string // optional filters
	options?: any // other params
	useV5?: boolean // whether or not to pass `/v5/` in the call
	useVersion?: boolean // whether or not to use the version
	useBasePath?: boolean // whether or not to use the basePath
}

const fetchWithToken = ({
	method = 'GET',
	resource,
	path,
	filters,
	options = {},
	useV5 = true,
	useVersion = true,
	useBasePath = true,
}: FetchWithTokenProps) => {
	// Grab the token from localstorage, otherwise use the token for local development
	// TODO: TS3.7
	const token = (getAuthData() && getAuthData().access_token) || 'INSERT_TOKEN_HERE'

	const {
		headers = { Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json' },
		...otherOptions
	} = options

	// Building the request URL
	const baseUrl = useBasePath ? `${api.baseUrl}` : ''
	const reqV5 = useV5 ? `/${api.v5}` : ''
	const reqResource = resource ? `/${resource}` : ''
	const reqVersion = useVersion ? `/${api.version}` : ''
	const reqPath = `/${path}`
	const reqFilers = filters ? `?${filters}` : ''

	// Putting it all together
	let reqUrl = `${baseUrl}${reqV5}${reqResource}${reqVersion}${reqPath}${reqFilers}`

	// If by any mistake only one param was used, we don't want a / at the begining
	if (reqUrl[0] === '/') {
		reqUrl = reqUrl.substring(1)
	}

	return fetch(reqUrl, {
		method,
		headers: {
			Authorization: `Bearer ${token}`,
			...headers,
		},
		...otherOptions,
	})
}

export default fetchWithToken
