import { connect } from 'react-redux'
import React, { FC, useEffect, ReactNode } from 'react'

import AppState from 'state/appState'
import fetchJif from 'state/jif/actions/fetchJif'
import fetchIAm from 'state/iAm/actions/fetchIAm'
import fetchConnectedUser from 'state/connectedUser/actions/fetchConnectedUser'
import fetchConnectedUserIdentity from 'state/connectedUserIdentity/actions/fetchConnectedUserIdentity'
import { setViewAs } from 'state/viewAs'
import getAuthData from 'utils/getAuthData'

interface ConnectedUserProviderProps {
	jif: AppState['jif']
	iAm: AppState['iAm']
	connectedUser: AppState['connectedUser']
	connectedUserIdentity: AppState['connectedUserIdentity']
	viewAs: AppState['viewAs']
	children: ReactNode
	setViewAs: typeof setViewAs
	fetchJif: typeof fetchJif
	fetchIAm: typeof fetchIAm
	fetchConnectedUserIdentity: typeof fetchConnectedUserIdentity
	fetchConnectedUser: typeof fetchConnectedUser
}

const ConnectedUserProvider: FC<ConnectedUserProviderProps> = ({
	connectedUser,
	iAm,
	children,
	fetchJif,
	jif,
	fetchConnectedUser,
	fetchConnectedUserIdentity,
	connectedUserIdentity,
	fetchIAm,
	setViewAs,
}) => {
	// If we have viewAs in URL, we set it in redux state
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const viewAsExternalUserKey = searchParams.get('viewAs')

		if (viewAsExternalUserKey) {
			setViewAs(viewAsExternalUserKey)
		}
	}, [setViewAs])

	// Fetching jif stuff
	useEffect(() => {
		if (!jif && getAuthData()) {
			const email = getAuthData() && getAuthData().principal // TODO TS3.7
			fetchJif(email)
		}
	}, [jif, fetchJif])

	// Fetching the iAm infos
	useEffect(() => {
		if (!iAm) {
			fetchIAm()
		}
	}, [iAm, fetchIAm])

	// Here we fetch the connected user's identity using his externalUserKey
	useEffect(() => {
		if (!connectedUserIdentity && connectedUser && Object.values(connectedUser).length > 0) {
			const externalUserKey =
				(connectedUser && connectedUser.users && connectedUser.users[0] && connectedUser.users[0].externalUserKey) ||
				connectedUser.username
			fetchConnectedUserIdentity(externalUserKey)
		}
	}, [connectedUserIdentity, connectedUser, fetchConnectedUserIdentity])

	// Here we fetch the connected user using his email or externalUserKey
	useEffect(() => {
		if (!connectedUser && getAuthData()) {
			let email

			if (window.location.search.includes('viewAs')) {
				const searchParams = new URLSearchParams(window.location.search)
				email = searchParams.get('viewAs') || '' // here, email is actually externalUserKey
			} else {
				email = getAuthData() && getAuthData().principal // TODO TS3.7
			}

			fetchConnectedUser(email)
		}
	}, [connectedUser, fetchConnectedUser])

	return <>{children}</>
}

export default connect(
	({ iAm, jif, connectedUser, connectedUserIdentity, viewAs }: AppState) => ({
		iAm,
		jif,
		connectedUser,
		connectedUserIdentity,
		viewAs,
	}),
	{
		fetchIAm,
		fetchJif,
		fetchConnectedUserIdentity,
		fetchConnectedUser,
		setViewAs,
	},
)(ConnectedUserProvider)
