import { Typography } from '@material-ui/core'
import React, { Component, ReactNode, ErrorInfo } from 'react'

interface ErrorBoundaryState {
	hasError: boolean
	error: Error | undefined
}

interface ErrorBoundaryProps {
	children?: ReactNode
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			error: undefined,
		}
	}

	static getDerivedStateFromError(error: Error) {
		console.error(error)
		// Update state so the next render will show the fallback UI.
		return {
			hasError: true,
			error,
		}
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(error)
		console.error(errorInfo)
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo)
		this.setState({
			error,
		})
	}

	render() {
		const { children } = this.props
		const { error, hasError } = this.state

		return hasError ? (
			<>
				<Typography variant="h6" component="h2" id="errorMessage">
					Woops! Something went wrong.
				</Typography>
				<pre>{error && error.toString()}</pre>
			</>
		) : (
			<>{children}</>
		)
	}
}

export default ErrorBoundary
