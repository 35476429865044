import getConfig from 'config'

const getAuthData = () => {
	const administrationJson = localStorage.getItem(getConfig().AUTH_DATA_STORAGE_KEY)

	if (administrationJson) {
		try {
			return JSON.parse(administrationJson)
		} catch (err) {
			console.warn('Local auth data parsing error:', err)
		}
	}

	return null
}

export default getAuthData
