export interface Language {
	key: string
	lang: string
	country: string
}

const languages = {
	deDE: {
		key: 'de-DE',
		lang: 'Deutsch',
		country: 'Deutschland',
	},
	enGB: {
		key: 'en-GB',
		lang: 'English',
		country: 'United Kingdom',
	},
	enUS: {
		key: 'en-US',
		lang: 'English',
		country: 'United States',
	},
	esES: {
		key: 'es-ES',
		lang: 'Español',
		country: 'España',
	},
	esMX: {
		key: 'es-MX',
		lang: 'Español',
		country: 'México',
	},
	frCA: {
		key: 'fr-CA',
		lang: 'Français',
		country: 'Canada',
	},
	frFR: {
		key: 'fr-FR',
		lang: 'Français',
		country: 'France',
	},
	ptBR: {
		key: 'pt-BR',
		lang: 'Português',
		country: 'Brasil',
	},
}

export default languages
