import { AnyAction, Dispatch } from 'redux'

import { setConnectedUser } from 'state/connectedUser'
import api from 'api'
import fetchWithToken from 'utils/fetchWithToken'
import handleStatusCodes from 'utils/handleStatusCodes'

/**
 * Retrieves the connected user based on the email in the token
 */
const fetchConnectedUser = (email: string): any => async (dispatch: Dispatch<AnyAction>) => {
	const route = `principals/${email}`

	await fetchWithToken({
		method: 'GET',
		resource: api.identity,
		path: route,
	})
		.then(async (response: Response) => {
			// Successful fetch
			if (response.status) {
				if (!handleStatusCodes(response.status, route, dispatch)) {
					dispatch(setConnectedUser({}))
					return false
				}

				const connectedUser: any = await response.json()

				if (connectedUser) dispatch(setConnectedUser(connectedUser))
			}
		})
		.catch((err: Error) => {
			// Something wrong happened during the fetch
			dispatch(setConnectedUser({}))
			console.error(err)
			// Don't dispatch error message because some people might never be connected <- TODO: verify if that's true
		})
}

export default fetchConnectedUser
