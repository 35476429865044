export type AuthConfig = {
	[key in 'base' | 'local' | 'development' | 'staging' | 'production']: {
		AUTH_DATA_STORAGE_KEY?: string
		LOCALE_STORAGE_KEY?: string
	}
}

const AuthConfig: AuthConfig = {
	base: {
		AUTH_DATA_STORAGE_KEY: 'lmi-end-user-portal',
		LOCALE_STORAGE_KEY: 'lmi-end-user-portal-locale',
	},
	local: {},
	development: {},
	staging: {},
	production: {},
}

export default AuthConfig
