import { createSlice, PayloadAction } from 'redux-starter-kit'

export interface ButtonConfiguration {
	userAgentConfigurationId?: string
	organizationId?: string
	buttons: any
}

const ButtonConfiguration = createSlice({
	initialState: null as unknown,
	name: 'buttonConfiguration',
	reducers: {
		setButtonConfiguration: (state, action: PayloadAction<ButtonConfiguration>) => (state = action.payload),
	},
})

export const { setButtonConfiguration } = ButtonConfiguration.actions

export default ButtonConfiguration.reducer
