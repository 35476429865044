import React, { FC } from 'react'
import { Avatar, Link, Fade, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { useTranslate } from 'react-polyglot'

//import AppSwitcher from 'components/AppSwitcher'
import LogoutButton from 'components/LogoutButton'
import LanguageSwitcher from 'components/LanguageSwitcher'
//import DarkModeToggle from 'components/DarkModeToggle'
import AppState from 'state/appState'

interface UserMenuProps {
	connectedUser?: AppState['connectedUser']
	iAm?: AppState['iAm']
	viewAs?: AppState['viewAs']
}

const UserMenu: FC<UserMenuProps> = ({ connectedUser, iAm, viewAs }) => {
	const t = useTranslate()

	return connectedUser ? (
		<Grid
			component="nav"
			container
			direction="column"
			alignItems="center"
			justify="space-between"
			wrap="nowrap"
			style={{ flex: 1 }}
		>
			{/* Top part */}
			<Grid item>
				<Link
					component="a"
					href="https://myaccount.logmeininc.com/"
					rel="noopener"
					target="_blank"
					title={t('Visit my LogMeIn account')}
				>
					{iAm && (
						<Fade in={true}>
							<Avatar
								alt={(iAm && iAm.displayName + ' Avatar') ?? ''}
								src={`https://avatars.servers.getgo.com/${viewAs || iAm.id}_medium.jpg`}
							/>
						</Fade>
					)}
				</Link>
			</Grid>

			{/* Bottom part */}
			<Fade in={true}>
				<Grid item container alignItems="center" direction="column">
					{/*<Grid item>
						<AppSwitcher />
					</Grid>*/}
					<Grid item>
						<LanguageSwitcher />
					</Grid>
					{/*<Grid item>
					<DarkModeToggle />
				</Grid>*/}
					<Grid item>
						<LogoutButton />
					</Grid>
				</Grid>
			</Fade>
		</Grid>
	) : null
}

export default connect(({ connectedUser, viewAs, iAm }: AppState) => ({
	connectedUser,
	iAm,
	viewAs,
}))(UserMenu)
